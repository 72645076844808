@import '../Utils/vars';

.footer {
    font-size: 1.4rem;
    padding: 1.5rem;

    a {
        color: $grey;
        text-decoration: underline;
    }

    .questions {
        align-items: center;
        background: $grey-dark;
        border: 1px solid $grey;
        color: $white;
        font-size: 1.6rem;
        padding: 1rem 1rem 1rem 1.5rem;
        text-decoration: none;

        i {
            font-size: 4rem;
        }
    }

    .links-bottom {
        line-height: 1.8em;
    }

}
