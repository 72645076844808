@import 'vars';

@mixin textoverflow() {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

// Gradients

// Horizontal gradient, from left to right

@mixin gradient-x($start-color: $start-gradient, $end-color: $end-gradient, $start-percent: 0%, $end-percent: 100%) {
    background-image: linear-gradient(to right, $start-color $start-percent, $end-color $end-percent);
    background-repeat: repeat-x;
}

// Vertical gradient, from top to bottom

@mixin gradient-y($start-color: $end-gradient, $end-color: $start-gradient, $start-percent: 0%, $end-percent: 100%) {
    background-image: linear-gradient(to bottom, $start-color $start-percent, $end-color $end-percent);
    background-repeat: repeat-x;
}



@mixin gradient-button() {
    position: relative;
    cursor: pointer;
    border: none;
    background-clip: padding-box;
    transition: .6s all;
    text-decoration: none;
    z-index: 1;
    
    &:after {
        transition: .6s all;
        position: absolute;  
        inset: 0;
        content: '';
        z-index: -1;
        background: linear-gradient(90deg, rgba(0,133,130,1) 0%, rgba(0,123,169,1) 50%, rgba(13,180,230,1) 100%);
        background-size: 200% 100%;
        background-position: 0% 0;
        opacity: 0;  // Start with gradient hidden
    }
    
    &:hover {
        color: white;

        &:after {
            opacity: 1; // Show gradient on hover
            background-position: 50% 0;
        }
    }
}
