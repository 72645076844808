// Break points

$break-ul: 1600px;
$break-xl: 1200px;
$break-lg: 992px;
$break-md: 768px;
$break-sm: 576px;
$break-xs: 480px;

// Greys

$grey: #666;
$grey-light: #f0f0f0;
$grey-light2: #e7e7e7;
$grey-light3: #b2b2b2;
$grey-dark: #333;

$grey-transparent: rgba(0, 0, 0, .125);

// Colors

$black: #000;
$white: #fff;

$blue-eni: #009ace;
$yellow-eni: #ffd100;
$green: #74af66;
$red: #c64242;
$blue: #4f9dba;
$orange: #ff8546;
$purple: #284669;
$pink: #f08386;
$brown: #B29D69;


$color-facebook: #3b5998;
$color-twitter: #1da1f2;
$color-gplus: #dd4b39;
$color-rss: #f26522;
$color-messenger: #0084ff;

$color-text: $grey;
$color-error: #db5e46;
$color-success: #74af66;

// Fonts

$font-regular: 'Montserrat', sans-serif;
$font-eni: 'enitabboldregular', sans-serif;

// Gradient
$start-gradient: rgba(100, 100, 100, 1);
$end-gradient: rgba(200, 200, 200, 1);

// Shadows

$black-shadow: rgba(0, 0, 0, .5);
$black-shadow-light: rgba(0, 0, 0, .2);
$black-opacity: rgba(0, 0, 0, 0);
$grey-opacity: rgba(110, 110, 110, .6);
$white-opacity: rgba(255, 255, 255, .6);
