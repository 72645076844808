@import '../Utils/vars';
// scss-lint:disable VendorPrefix PseudoElement

.form-generic {

    ::-webkit-input-placeholder { // Chrome/Opera/Safari
        color: $grey-dark;
    }

    ::-moz-placeholder { // Firefox 19+
        color: $grey-dark;
        opacity: 1;
    }

    :-ms-input-placeholder { // IE 10+
        color: $grey-dark;
    }

    :-moz-placeholder { // Firefox 18-
        color: $grey-dark;
    }

    label {
        cursor: pointer;
        font-weight: normal;
        margin: 0;

        &.required::after {
            content: ' *';
            position: absolute;
        }
    }

    select,
    textarea,
    .custom-file-label,
    [type="file"],
    [type="date"],
    [type="tel"],
    [type="password"],
    [type="email"],
    [type="search"],
    [type="text"] {
        background: $white;
        border: 1px solid $grey-light;
        color: $grey-dark;
        font-size: 1.5rem;
        height: 4rem;
        padding: 1rem 2rem;
    }


    .custom-file-label,
    [type="file"],
    [type="date"],
    select,
    textarea {
        border: 1px solid $grey-light;
        height: auto;
        min-height: 4rem;
    }

    select {
        -moz-appearance: none;
        -webkit-appearance: none;
        border-radius: initial;
        padding: 1rem 4rem 1rem 1rem;
    }

    textarea {
        border-radius: initial;
        display: block;
        min-height: 200px;
        padding: 1rem 1.5rem;
        width: 100%;
    }

    .custom-file {
        height: auto;
    }

    .custom-file-input {
        cursor: pointer;
    }

    .custom-file-label {
        line-height: 2rem;
        overflow: hidden;
        padding: 1.5rem 2rem;
        white-space: nowrap;

        // scss-lint:disable ImportantRule
        &::after {
            background: $grey;
            color: $white;
            content: 'PARCOURIR' !important;
            font-size: 1.5rem;
            height: auto;
            padding: 1.5rem 2rem;
        }

        // scss-lint:enable ImportantRule

        &.selected::after {
            background: $grey-light;
            color: $grey;
        }
    }

    .form-error {
        color: $color-error;

        ::-webkit-input-placeholder { // Chrome/Opera/Safari
            color: $color-error;
        }

        ::-moz-placeholder { // Firefox 19+
            color: $color-error;
            opacity: 1;
        }

        :-ms-input-placeholder { // IE 10+
            color: $color-error;
        }

        :-moz-placeholder { // Firefox 18-
            color: $color-error;
        }

        button,
        textarea,
        input {
            color: $color-error;
        }
    }

    .msg-error {
        background: $color-error;
        color: $white;
        padding: 1rem;
        text-align: left;
    }

    .msg-success {
        background: $color-success;
        color: $white;
        padding: 1rem;
        text-align: left;
    }

    .msg-info {
        font-size: 1.2rem;
    }

    .form-check {
        padding-left: 3rem;

        input {
            margin-left: -3rem;
            margin-right: 2rem;
            opacity: 0;
        }

        a {
            text-decoration: underline;
        }

        [type="checkbox"]:not(:checked) + label::before,
        [type="checkbox"]:checked + label::before {
            border: 1px solid $grey-light2;
            border-radius: 3px;
            content: '';
            height: 2rem;
            left: 0;
            position: absolute;
            top: 0;
            width: 2rem;
        }

        [type="checkbox"]:not(:checked) + label::after,
        [type="checkbox"]:checked + label::after {
            color: $grey-dark;
            content: 'done';
            font-family: 'Material Icons', sans-serif;
            font-size: 1.8rem;
            font-weight: 600;
            height: 1em;
            left: 2px;
            line-height: 1em;
            position: absolute;
            top: 1px;
            transition: all .2s;
        }

        // Aspect si "pas cochee"
        [type="checkbox"]:not(:checked) + label::after {
            opacity: 0;
            transform: scale(0);
        }

        // Aspect si "cochee"
        [type="checkbox"]:checked + label::after {
            opacity: 1;
            transform: scale(1);
        }
    }

    .custom-date {
        position: relative;

        &::after {
            content: '\f073';
            font-family: "Font Awesome 5 Free", sans-serif;
            position: absolute;
            right: 2rem;
            top: 1rem;
        }

        &:hover {
            &::after {
                display: none;
            }
        }

        input:focus {
            background: $white;
            position: relative;
            z-index: 1;
        }
    }
}
