@import '../Utils/vars';

.custom-select-contain {
    position: relative;

    //noinspection CssNoGenericFontName
    &::after {
        background: none;
        color: $grey;
        content: 'expand_more';
        font-family: 'Material Icons', sans-serif;
        font-size: 24px;
        pointer-events: none;
        position: absolute;
        right: 1.5rem;
        top: 50%;
        transform: translateY(-50%);
    }

    .fake-select,
    select {
        background: transparent;
        border: 1px solid $grey-light3;
        border-radius: 0;
        font-size: 1.6rem;
        height: 5rem;
        padding: 1rem 4rem 1rem 2rem;
        text-align: left;
        width: 100%;
    }

    select {
        -moz-appearance: none;
        -webkit-appearance: none;
    }

    .fake-select {
        background: $grey-light;
        bottom: 0;
        cursor: pointer;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;

        span {
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        ul {
            background: $white;
            border: 1px solid $grey-light3;
            display: none;
            left: -1px;
            list-style: none outside;
            margin: 0;
            padding: 0;
            position: absolute;
            right: -1px;
            top: 100%;
            z-index: 10;

            li {
                margin: 0;
                padding: 1rem 2rem;

                &.active,
                &:hover {
                    background: $grey-dark;
                    color: $white;
                }

            }
        }
    }
}
