// Fonts
@import 'vars';

@font-face {
    font-family: 'enitabboldregular';
    src: url('../../../fonts/enitabbol-webfont.woff2') format('woff2'),
    url('../../../fonts/enitabbol-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

.md-18 {
    font-size: 18px;
}
