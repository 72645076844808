@import 'Utils/fonts';
@import 'Utils/vars';
@import 'Utils/mixins';
@import 'Components/header';
@import 'Components/button';
@import 'Components/footer';
@import 'Components/select';
@import 'Components/form';
@import '~@ckeditor/ckeditor5-build-classic/build/ckeditor-style.css';

html {
    font-size: 62.5%;
    height: 100%;
}

body {
    background-color: $grey-light;
    color: $grey-dark;
    font-family: $font-regular;
    font-size: 1.5rem;
    min-height: 100%;
    min-width: 370px;
    position: relative;
    width: 100%;
}

h1,
h2,
h3,
.like-h1,
.like-h2 {
    font-family: "enitabboldregular", sans-serif;
    font-weight: 700;
}

h1,
.like-h1 {
    font-size: 2.5rem;
}

h2,
.like-h2 {
    font-size: 2.1rem;
}

h3 {
    font-size: 1.8rem;
}

h4 {
    color: $grey;
}

p {
    font-size: 1.4rem;

    strong {
        font-weight: 700;
    }
}

a {
    color: $grey-dark;
}

ul {
    padding: 0;
}


.gradient-button {
    @include gradient-button;
}

.container-fluid {
    max-width: 1568px;
}

.select2-container--default.select2-container .select2-selection--multiple {
    border: 1px solid $grey-light;
}

.cookies-row {
    background: $grey;
    bottom: 0;
    box-shadow: 0 0 20px $grey-opacity;
    color: $grey-light;
    display: none;
    left: 0;
    padding: .5rem 0;
    position: fixed;
    right: 0;
    width: 100%;
    z-index: 50;

    button,
    a {
        border: 0;
        cursor: pointer;
        flex: 0 0 auto;
        margin: 0 1rem;
        padding: .5rem 1rem;

        &:hover,
        &:focus {
            text-decoration: none;
        }

    }

    p {
        flex: 0 1 auto;
        margin: 0 2rem 0 0;
    }
}

.nowrap {
    white-space: nowrap;
}

.text-wrap {
    white-space: normal;
}

.text-underline {
    text-decoration: underline;
}


.text-underline-hover:hover {
    text-decoration: underline;
}

.text-decoration-none,
.text-decoration-none:hover {
    text-decoration: none;
}

.no-radius {
    border-radius: 0;
}

.text-small {
    font-size: 1.3rem;
}

.bloc-border-btn {
    border: 1px solid $grey-light2;
    border-bottom: 0;
}

.border-full {
    border: 1px solid $grey-light2;
}

.border-b {
    border-bottom: 1px solid $grey-light2;
}

.border-deco {
    &::after {
        background: $grey-dark;
        content: '';
        display: block;
        height: 3px;
        margin-top: .5rem;
        width: 60px;
    }
}

.breadcrumb-item + .breadcrumb-item::before {
    content: '>';
}

.breadcrumb {
    background: 0;
    font-size: 1.2rem;
    margin: 0;
    padding: 1rem 0;

    li a {
        color: $grey;
        font-weight: bold;
    }
}

.link-back,
.link-more {
    font-size: 1.4rem;
    text-decoration: underline;

    i {
        font-size: 3rem;
        width: 2rem;
    }

    &:hover {
        color: $grey;
    }
}

.link-back {
    margin-left: -1rem;

    i {
        width: auto;
    }
}

.avatar-add {
    display: block;
    position: relative;
    width: 100px;

    &:hover,
    &:focus {
        text-decoration: none;
    }

    &::after {
        background: $grey-dark;
        border-radius: 50%;
        bottom: 0;
        box-shadow: none;
        color: $white;
        content: 'add_a_photo';
        cursor: pointer;
        font-family: 'Material Icons', sans-serif;
        font-size: 24px;
        font-weight: normal;
        line-height: 46px;
        position: absolute;
        text-align: center;
        text-shadow: none;
        transform: translate(50%, -25%);
        width: 46px;
    }
}

.box-title {

    border: 1px solid $grey-light2;

    .title {
        background: $grey;
        color: $white;
        font-family: $font-eni;
        font-size: 2rem;
        padding: 1rem 1rem 1rem 1.5rem;

        .material-icons {
            font-size: 2.2rem;
            margin-right: .5rem;
            vertical-align: text-bottom;

        }

    }

    .content {
        background: $white;
        padding: 1.5rem;
    }
}

.news-list {
    list-style: none outside;

    li {
        border-bottom: 1px solid $grey-light2;
        margin: 0 0 1rem;
        padding: 0 0 1rem;

        p {
            flex-grow: 1;
            font-size: 1.4rem;
            max-height: 42px;
            overflow: hidden;
        }
    }

    .btn {
        padding: 0;

        .material-icons {
            font-size: 2.2rem;
        }
    }

    .first-news {
        p {
            font-size: 1.6rem;
            max-height: 48px;
        }
    }

    a {

        &:hover,
        &:focus {
            color: $grey-dark;
            text-decoration: none;

            .btn-primary {
                background: $grey-dark;
                color: $white;
            }
        }
    }
}

.wysiwyg {
    p {
        font-size: 1.6rem;
    }

    a {
        text-decoration: underline;
    }
}

.links-list {
    border-bottom: 1px solid $grey-light2;
    list-style: none outside;
    padding: 0 0 .5rem;

    li a {
        background: $grey-dark;
        color: $white;

        span:first-child {
            flex-basis: 100%;
            flex-grow: 1;
            flex-shrink: 1;
            padding: 0 1rem;
        }
    }

    a:focus,
    a:hover {
        background: $grey-light2;
        color: $grey-dark;
        text-decoration: none;

        .btn {
            background: $blue-eni;
            color: $white;
        }
    }

    .btn {
        padding: .5rem;

        .material-icons {
            font-size: 2.2rem;
        }
    }
}

.btn-tool {
    background: $blue-eni;
    color: $white;

    .picto {
        align-items: center;
        background: $white;
        border: 1px solid $grey-light3;
        display: flex;
        justify-content: center;
        max-width: 86px;
        min-height: 86px;
        min-width: 86px;
    }

    .img-thumbnail {
        border: 0;
    }

    &:focus,
    &:hover {
        color: $black;
        text-decoration: none;
    }
}

.accordion-item {
    border: 1px solid $grey-light3;

    .accordion-head {
        background: $grey-light2;

        a {
            color: $grey-dark;
            white-space: initial;
        }
    }

    .accordion-body {
        border-top: 1px solid $grey-light3;
        padding: 2rem;
    }
}

.training-list {
    a {
        line-height: 1.1em;
        text-decoration: none;

        &:hover,
        &:focus {
            background: $grey-light2;
            color: $grey-dark;

            .btn-primary {
                background: $blue-eni;
                color: $white;
            }
        }

        span:first-child {
            flex-basis: 100%;
            flex-shrink: 1;
        }
    }
}

.training-list-links {
    .flex-column {
        flex-basis: 100%;
        flex-shrink: 1;
    }

    a {
        font-size: 14px;
    }
}

.operation-list {
    a {

        &:hover,
        &:focus {
            background: $grey-light2;
            color: $grey-dark;
            text-decoration: none;

            .btn-primary {
                background: $blue-eni;
                color: $white;
            }
        }
    }

    // BUG IE 11
    .flex-column .border-full {
        flex: 0 0 auto;
    }

    .flex-column .btn-secondary .flex-grow-1 {
        flex: 1 1 100%;
    }
}

.contacts-list {
    a {
        &:hover,
        &:focus {
            color: $grey-dark;
            text-decoration: none;

            .btn-primary {
                background: $grey-dark;
                color: $white;
            }
        }

    }
}

// FIX IE11 BUG
.contact-detail {
    .row .d-flex:first-child a {
        display: inline-block;
        width: 100%;
    }
}

.toolbox,
.toolbox-list {
    .picto img {
        flex-shrink: 0;
    }
}

// FIX BUG IE11
.actuality-list {
    .d-flex .material-icons {
        flex: 0 0 auto;
        height: auto;
    }
}

// FIX BUG IE11
.media-list {
    .flex-column .border-full {
        flex: 0 0 auto;
    }
}

.notifications {
    border: 1px solid $grey-light2;

    .item {
        border-bottom: 1px solid $grey-light2;
        padding: 1rem;

        span:first-child {
            flex: 0 0 100px;
        }

        &:last-child {
            border: 0;
        }
    }

    //scss-lint:disable QualifyingElement
    a.item {
        padding-right: 3rem;
        position: relative;

        &::after {
            align-items: center;
            background: $blue-eni;
            bottom: 0;
            color: $white;
            content: 'chevron_right';
            display: flex;
            font-family: 'Material Icons', sans-serif;
            font-feature-settings: 'liga';
            font-size: 2.2rem;
            padding: 0 .2rem;
            position: absolute;
            right: 0;
            text-align: center;
            top: 0;
            width: 26px;
        }
    }
    //scss-lint:enable QualifyingElement

    a:hover,
    a:focus {
        background: $grey-dark;
        color: $white;
        text-decoration: none;
    }

    .no-read {
        font-weight: bold;
    }
}

.message-list {
    border: 1px solid $grey-light3;

    .item {
        align-items: center;
        border-bottom: 1px solid $grey-light3;
        color: $grey-dark;
        padding: 1rem;
        position: relative;

        .col-avatar {
            flex: 0 0 52px;

            &[data-notif] {
                position: relative;

                &::before {
                    background: $white;
                    border-radius: 50%;
                    box-shadow: 0 .2rem 1rem $black-shadow-light;
                    color: $grey-dark;
                    content: attr(data-notif);
                    display: inline-block;
                    font-size: 1.3rem;
                    left: 3rem;
                    line-height: 1.5rem;
                    position: absolute;
                    text-align: center;
                    top: 0;
                    width: 1.5rem;
                }
            }
        }

        .col-name,
        .col-object {
            flex: 1 1 auto;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .col-name {
            align-self: flex-start;
        }

        .col-object {
            bottom: 1rem;
            left: calc(52px + 1rem);
            position: absolute;
            right: calc(60px + 1rem);
        }

        .col-time {
            flex: 0 0 60px;
            text-align: right;
        }

        &:last-child {
            border: 0;
        }
    }

    //scss-lint:disable ImportantRule, QualifyingElement
    .no-read {
        background: $blue-eni;

        svg path {
            fill: $grey-dark !important;
        }

        svg g[stroke] {
            stroke: transparent !important;
        }

        .col-name {
            font-weight: bold;
        }
    }
    //scss-lint:enable ImportantRule, QualifyingElement
}

.btn-add-group {

    &:hover,
    &:focus {
        color: $grey;
        text-decoration: none;
    }

    i {
        font-size: 3.4rem;
    }
}

.message-item {
    .message {
        display: inline-block;
        max-width: 550px;
        padding: 0 calc(1.5rem + 40px);
        width: 100%;

        .bulle {
            border: 1px solid $grey-light2;
            margin-bottom: .5rem;
            padding: .5rem 1rem;
            text-align: left;
            word-wrap: break-word;
        }

        .nb-read {
            font-size: 1.2rem;
            font-style: italic;
            text-align: right;
        }
    }
}

.message-item-right {
    text-align: right;

    .header-message {
        flex-direction: row-reverse;
    }

    .message {
        .bulle {
            background: $grey-light2;
        }
    }
}

.messenger {
    padding-bottom: 6rem;

    .form-messenger {
        background: $white;
        border-top: 1px solid $grey-light2;
        bottom: 0;
        left: 0;
        padding: 1.5rem 2rem;
        position: fixed;
        right: 0;

        textarea {
            min-height: 44px;
        }

        .btn {
            padding: .5rem 1.5rem;
        }
    }
}

// Pagination
.navigation {
    .page-link {
        color: $blue-eni;
    }
    .page-item.active .page-link {
        background-color: $blue-eni;
        border-color: $blue-eni;
        
    }
}

.dropdown-filter {
    .dropdown-toggle {
        min-width: 112px;

        &::after {
            display: none;
        }
    }

    &.show {
        .dropdown-toggle {
            background: $grey-dark;
        }
    }

    .dropdown-menu {
        background: $grey-dark;
        color: $white;
        font-size: 1.6rem;
        margin: 1rem 0;
        min-width: 200px;

        &::before {
            border: 10px solid transparent;
            border-bottom-color: $grey-dark;
            bottom: 100%;
            content: '';
            height: 0;
            pointer-events: none;
            position: absolute;
            right: 40px;
            width: 0;
        }

        .form-check {
            line-height: 2rem;

            [type="checkbox"] + label::before {
                background: $white;
                border-color: $white;
            }
        }

        .btn-primary:hover,
        .btn-primary:focus {
            background: $blue-eni;
            color: $white;
        }
    }
}

.form-search {
    background: $white;
    border-top: 1px solid $grey-light2;
    bottom: 0;
    left: 0;
    padding: 1rem;
    position: fixed;
    right: 0;
    z-index: 10;

    > div {
        position: relative;
    }

    > div::before {
        content: 'search';
        font-family: 'Material Icons', sans-serif;
        font-feature-settings: 'liga';
        font-size: 2.8rem;
        left: 1.5rem;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }


    //scss-lint:disable QualifyingElement
    input[type="search"] {
        height: auto;
        padding-left: 5rem;
    }
    //scss-lint:enable QualifyingElement

    .d-flex > [type="submit"] {
        flex-shrink: 0;
    }

    .dropdown-filter {
        .dropdown-menu {
            min-width: 100%;

            &::before {
                border-bottom-color: transparent;
                border-top-color: $grey-dark;
                bottom: auto;
                top: 100%;
            }
        }
    }
}

.form-search-noicon {
    > div::before {
        display: none;
    }
}

.main {
    .bg-green-hover:hover,
    .bg-green {
        background: $green;
    }

    .bg-blue-hover:hover,
    .bg-blue {
        background: $blue;
    }

    .bg-red-hover:hover,
    .bg-red {
        background: $red;
    }

    .bg-blue-eni-hoveri:hover, .bg-blue-eni {
        background: $blue-eni;
    }

    .bg-yellow-hover:hover,
    .bg-yellow {
        background: $yellow-eni;
    }

    .bg-pink-hover:hover,
    .bg-pink {
        background: $pink;
    }

    .bg-purple-hover:hover,
    .bg-purple {
        background: $purple;
    }

    .bg-orange-hover:hover,
    .bg-orange {
        background: $orange;
    }

    .bg-brown-hover:hover,
    .bg-brown {
        background: $brown;
    }

    .bg-grey-light-hover:hover,
    .bg-grey-light {
        background: $grey-light2;
    }

    .bg-grey-hover:hover,
    .bg-grey {
        background: $grey;
    }
}

.bg-modal {
    background: url('../../img/logo-eni-without-text.svg') no-repeat 50vw 10px $grey-light;
    background-size: 300px auto;
}

.modal-body {
    ul {
        font-size: 1.6rem;
        padding-left: 3rem;
    }

    p {
        font-size: 1.5rem;
    }

    .modal-title {
        font-size: 3rem;
        line-height: 1;
    }
}


// Small devices (phones, 576px and up)
@media (min-width: $break-sm) {

    .messenger {
        padding-bottom: 6rem;
        position: relative;

        .form-messenger {
            bottom: -1.5rem;
            position: absolute;
        }
    }

    .form-search {
        border: 0;
        padding: 0;
        position: relative;

        .dropdown-filter {
            .dropdown-menu {
                max-width: 50%;
                min-width: 200px;

                &::before {
                    border-bottom-color: $grey-dark;
                    border-top-color: transparent;
                    bottom: 100%;
                    top: auto;
                }
            }
        }

    }
}

// Medium devices (tablets, 768px and up)
@media (min-width: $break-md) {

    .w-md-35 {
        width: 35%;
    }

    .modal-lg {
        max-width: 80%;
    }

    .bg-modal {
        background-position: 30px 110px;
        background-size: 500px auto;
    }

    .notifications {
        .item {
            span:last-child {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }

    .message-list {
        .item {
            .col-name {
                align-self: center;
            }

            .col-object {
                flex: 0 0 290px;
                margin-left: 1rem;
                position: static;
            }
        }
    }
}

// Large devices (desktops, 992px and up)
@media (min-width: $break-lg) {
    .w-lg-auto {
        width: auto;
    }

    .modal-lg {
        max-width: 800px;
    }

    .message-list {
        .item {

            .col-object {
                flex: 0 0 450px;
            }
        }
    }

    .message-item .message {
        max-width: 580px;
        padding: 0 calc(3rem + 40px);
    }

}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: $break-xl) {
}

.no-figure-marge {
    figure {
        margin: 0;
    }
}
 #modalHome {
     .container-fluid {
         padding: 0;
         > .row {
             margin: 0;
             > div {
                 figure {
                     max-height: 100%;
                     max-width: 100%;
                     img {
                         max-height: 100%;
                         max-width: 100%;
                     }
                     &.image-style-align-center,
                     &.image-style-side,
                     &.image-style-align-left,
                     &.image-style-align-right {
                        max-height: none;
                         img {
                             max-height: none;
                         }
                     }
                 }
             }
         }
     }
 }
