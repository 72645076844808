@import '../Utils/vars';

.btn {
    border: 0;
    border-radius: 0;
    cursor: pointer;
    display: inline-block;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1;
    padding: 1.5rem;
}

.btn-primary {
    background-color: $blue-eni;
    color: $white;

    &:hover,
    &:focus {
        background-color: $grey-dark;
        color: $white;
    }
}

.btn-secondary {
    background-color: $grey-dark;
    color: $white;

    &:hover,
    &:focus {
        background-color: $grey-dark;
        color: $white;
    }
}

.btn-facebook {
    background-color: $color-facebook;
    color: $white;

    &:hover,
    &:focus {
        background-color: darken($color-facebook, 10%);
        color: $white;
    }
}

.btn-twitter {
    background-color: $color-twitter;
    color: $white;

    &:hover,
    &:focus {
        background-color: darken($color-twitter, 10%);
        color: $white;
    }
}

.btn-dark {
    background-color: $grey;
    transition: all .2s;

    &:hover,
    &:focus {
        background-color: $white;
        color: $black;
    }
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
    background: $grey-light;
    color: $grey;
}

.btn-primary.disabled,
.btn-primary:disabled {
    background-color: $grey-dark;
    color: $white;
}
