@import '../Utils/vars';
// scss-lint:disable ImportantRule
// scss-lint:disable IdSelector

.header {
    background: $white;
    border-bottom: 1px solid $grey-light2;
    min-width: 370px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;

    a {
        color: $grey-dark;

        &:hover,
        &:focus {
            text-decoration: none;
        }
    }

    .navbar {
        height: 6rem;
        justify-content: space-between;
        padding: 0;

        .dropdown-toggle {
            color: $grey;

            i {
                vertical-align: middle;
            }
        }

        .dropdown-toggle::after {
            content: none;
        }

        .dropdown-menu {
            font-size: 1.4rem;
            left: auto;
            right: 0;
        }
    }

    .account-bar {
        align-items: center;
        display: flex;

        > a {
            display: flex;
            position: relative;

            &::after {
                background: $yellow-eni;
                border-radius: 50%;
                box-shadow: 0 .2rem 1rem $black-shadow-light;
                color: $grey-dark;
                content: attr(data-notif);
                display: inline-block;
                line-height: 2rem;
                position: absolute;
                right: -1rem;
                text-align: center;
                top: -.5rem;
                width: 2rem;
            }
        }
    }

    .navbar-brand {
        display: flex;
        padding: 0;

        img {
            width: 120px;
        }
    }

    .navbar-nav {
        padding: 0;
    }
}

.main {
    padding-top: 6rem;
}

.navbar-collapse {
    background: $white;

    .navbar-nav {

        .nav-item {
            border-bottom: 1px solid $grey-light2;
        }

        .nav-link {
            align-items: center;
            display: flex;
            font-size: 1.7rem;
            line-height: 1em;
            padding: .5rem 1rem;

            .material-icons {
                border: 2px solid transparent;
                border-radius: 50%;
                color: $white;
                margin-right: 1rem;
                padding: .5rem;
            }

            &.active,
            &:hover,
            &:focus {
                color: $white;

                .material-icons {
                    background: none;
                    border: 2px solid $white;
                    color: $white;
                }
            }
        }

        .bg-blue-eni-hover {

            &.active,
            &:hover,
            &:focus {
                color: $grey-dark;

                .material-icons {
                    border-color: $grey-dark;
                    color: $grey-dark;
                }
            }
        }

    }
}


// Small devices (mobiles, 576px and up)
@media (min-width: $break-sm) {
    .main {
        padding-top: 8rem;
    }
}

// Xtra Large devices (desktops, 1200px and up)
@media (min-width: $break-xl) {

    .main {
        padding-top: 7rem;
    }

    // scss-lint:disable ImportantRule
    .navbar-collapse:not(.show) {
        display: flex !important;
    }

    .navbar-toggler {
        display: none;
    }

    .navbar-collapse {
        align-items: stretch;
        flex-direction: column;
        justify-content: space-between;
    }
}

// Ultra large devices (1600px and up)
@media (min-width: $break-ul) {
}

// Medium devices and Mobile (1199px and down)
@media (max-width: $break-xl - 1) {

    .navbar-toggler {
        border: 0;
        border-right: 1px solid $grey-light2;
        font-size: 2rem;
        height: 6rem;
        left: -15px;
        padding: 0;
        position: relative;
        width: 6rem;


        .navbar-toggler-icon {
            height: 18px;
            position: relative;
            width: 20px;

            &::before,
            &::after {
                display: none;
            }

            span {
                background: $grey-dark;
                display: block;
                height: 3px;
                margin-bottom: 3px;
            }

            span:last-child {
                margin-bottom: 0;
            }
        }
    }

}

// Menu no fixed if small height
@media (max-height: 500px) and (max-width: $break-lg - 1) {
    .header {
        position: absolute;
    }
}
